






























































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {Company} from '@/models/Company';
import {FileTransferType} from '@/enums/FileTransferType';
import SelectStatusEdi from '@/components/edi/SelectStatusEdi.vue';
import FilterBy from '@/components/commons/FilterBy.vue';
import FilterByItem from '@/components/commons/FilterByItem.vue';
import {Range} from '@/models/Range';
import Filters from '@/utils/Filters';
import EdiRowAction from '@/components/edi/EdiRowAction.vue';
import {DateUtils} from '@/utils/DateUtils';
import {Utils} from '@/utils/Utils';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import {Authority} from '@/models/Authority';
import SelectTipoIntegracao from '@/components/edi/SelectTipoIntegracao.vue';
import SelectTipoLayout from '@/components/edi/SelectTipoLayout.vue';
import {EdiListInfo} from '@/models/EdiListInfo';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');

export class EdiFormFilters {
    public show: boolean = false;
    public id: string = '';

    public dataGeracao: string = '';
    public dataIntegracao: string = '';
    public dataEnvio: string = '';

    public tipo: string = '';
    public layout: string = '';

    public nrFatura: string = '';
    public nrOcorrencia: string = '';

    public numeroCteIni: string = '';
    public numeroCteMax: string = '';
    public numeroCte: string = '';

    public serieCte: string = '';
}

@Component({
    name: 'Edi',
    components: {
        SelectTipoLayout,
        SelectTipoIntegracao,
        EdiRowAction,
        SelectStatusEdi,
        FilterBy,
        FilterByItem,
        ErrorMessage,
    },
})

export default class Edi extends Vue {

    public $refs!: {
        table: any,
        drawer: any,
        formaPagamento: any,
        form: Form,
    };

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    @AuthStore.Action
    public updateAuthorities!: () => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    // drawer
    public dialog: boolean = false;

    // filters
    public filterBrDate = Vue.filter('brDateTime');
    public filterCurrencyStringToNumber = Vue.filter('currencyStringToNumber');

    /*
    * propriedade pra filtro de busca
    */
    public formFilters: EdiFormFilters = new EdiFormFilters();

    public formLabelWidth: any = '120px';

    public isLoading: boolean = false;

    public totalRecords: number = 0;

    /*
    * Altura default da tabela da listagem
    */
    public tableHeight: string = '300px';

    public zip: FileTransferType = FileTransferType.ZIP;

    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'id',
            type: 'asc',
        },
        page: 1,
        perPage: 50,
    };

    public rows: EdiListInfo[] = [] as EdiListInfo[];

    /**
     * Regras de validacao
     */
    public rules: any = {
        numeroCteIni: [
            {
                validator: (rule, value, callback) => {
                    if ((!Vue.prototype.$isEmpty(value) && Vue.prototype.$isEmpty(this.formFilters.numeroCteMax)) ||
                        (Vue.prototype.$isEmpty(value) && !Vue.prototype.$isEmpty(this.formFilters.numeroCteMax))) {
                        this.setErrorMessage('');
                        callback();
                    }
                },
            },
        ],
    };

    public columns = [
        {
            label: 'Código',
            sortable: true,
            width: '20px',
            field: 'id',
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Data de Geração',
            width: '60px',
            field: 'dataGeracao',
            type: 'date',
            formatFn: this.formatDateFn,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Enviado',
            width: '15px',
            field: 'enviado',
            type: 'string',
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Data de Envio',
            width: '60px',
            field: 'dataEnvio',
            type: 'date',
            formatFn: this.formatDateFn,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Tipo',
            width: '30px',
            field: 'tipo',
            type: 'string',
            tdClass: 'text-center',
            thClass: 'text-center',
            formatFn: this.formatType,
        },
        {
            label: 'Layout',
            width: '30px',
            field: 'layout',
            type: 'string',
            tdClass: 'text-center',
            thClass: 'text-center',
            formatFn: this.formatLayout,
            sortable: false,
        },
        {
            label: 'Ações',
            width: '80px',
            field: 'actions',
            tdClass: 'text-left',
            thClass: 'text-left',
            sortable: false,
        },
    ];

    public formatDateFn(param) {
        return param.value ? this.filterBrDate(param.value) : '-';
    }

    public formatType(value) {
        let retval = '-';
        if (value === '1' || value === 1 ) {
            retval = 'ARQUIVO';
        } else if (value === '2' || value === 2 ) {
            retval = 'COMUNICAÇÃO';
        }
        return retval;
    }

    public formatLayout(value) {
        let retval = '-';
        if (value === '1' || value === 1 ) {
            retval = 'DOCCOB';
        } else if (value === '2' || value === 2 ) {
            retval = 'CONEMB';
        } else if (value === '3' || value === 3 ) {
            retval = 'OCORREN';
        } else if (value === '99' || value === 99 ) {
            retval = 'OUTROS';
        }
        return retval;
    }

    public renderEnviado(value) {
        return value === '1' ? 'fa fa-check' : 'fa fa-times';
    }

    /*
    * dispara a busca e fecha formulário
    */
    public search() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.formFilters.show = true;
                this.$refs.drawer.closeDrawer();
                this.onPageChange('1');
            } else {
                return false;
            }
        });
    }

    public formatString(str, len = 50, reduce = 47): string {
        return Utils.formatSizeStringRow(str, len, reduce);
    }

    public updateParams(newProps): void {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
    }

    public onPageChange(params): void {
        this.updateParams({page: params.currentPage});
        this.loadItems();
    }

    public onPerPageChange(params): void {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
    }

    public onSortChange(params): void {
        if (params.length) {
            const fieldParams = params[0].field;
            this.updateParams({
                sort: {
                    field: fieldParams,
                    type: params[0].type,
                },
            });
        }
        this.loadItems();
    }

    public loadItems(): void {
        if (!this.currentCompany.id) {
            return;
        }
        this.applyFilters();
        this.$axios
            .post(
                `/edi/list/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                const data = response.data || {};
                this.totalRecords = data.recordsFiltered;
                this.rows = data.resources || [];
            }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        }).finally(() => this.isLoading = false);
    }

    public getFiltersToExport(): any {
        return this.serverParams;
    }

    public calcPositionTable(): void {
        if (this.$refs.table != null) {
            const tableHeader = document.getElementsByClassName('vgt-fixed-header');
            const tableFooter = document.getElementsByClassName('vgt-wrap__footer');
            const headerPos = tableHeader[0].getBoundingClientRect();
            const footerPos = tableFooter[0].getBoundingClientRect();
            const calc = footerPos.top - (headerPos.bottom - headerPos.height);
            this.tableHeight = calc.toFixed(0);
        }
    }

    public getFixedHeigthTable(): string {
        return `${this.tableHeight}px`;
    }

    public cancelForm(): void {
        this.dialog = false;
    }

    /**
     * abre formulario de pesquisa
     */
    public openFormPesq(): void {
        this.setErrorMessage('');
        this.applyMask();
    }

    public getNumeroCtePesq(): any {
        if (this.formFilters.numeroCteIni && this.formFilters.numeroCteMax) {
            return new Range(this.formFilters.numeroCteIni, this.formFilters.numeroCteMax);
        }
    }

    /**
     * Mascara de formatacao
     */
    public applyMask() {
        const im = new Inputmask('99/99/9999');
        const elDateGeracaoIni = document.getElementById('dtger-range-start')!;
        const elDateGeracaoEnd = document.getElementById('dtger-range-end')!;
        const elDateIntegracaoInit = document.getElementById('dtint-range-start')!;
        const elDateIntegracaoEnd = document.getElementById('dtint-range-end')!;
        const elDateEnvioInit = document.getElementById('dtenv-range-start')!;
        const elDateEnvioEnd = document.getElementById('dtenv-range-end')!;
        im.mask(elDateGeracaoIni);
        im.mask(elDateGeracaoEnd);
        im.mask(elDateIntegracaoInit);
        im.mask(elDateIntegracaoEnd);
        im.mask(elDateEnvioInit);
        im.mask(elDateEnvioEnd);
    }

    /**
     * Reset filtros de pesquisa
     */
    public cleanAll(): void {
        this.formFilters.show = false;
        this.onRefreshClick();
    }

    public hasAuthOnCurrentCompany(auth: string, currentCompany: Company): boolean {
        return this.authorityList.some(
            (authority) => authority.empresaLoginId === currentCompany.id && authority.auth === auth,
        );
    }

    public changeCte(val: any): void {
        if (val === '') {
            this.formFilters.numeroCteIni = '';
            this.formFilters.numeroCteMax = '';
        } else if (this.formFilters.numeroCteIni === '') {
            this.formFilters.numeroCteIni = val;
        } else if (this.formFilters.numeroCteMax === '') {
            this.formFilters.numeroCteMax = val;
        } else if (parseInt(this.formFilters.numeroCteMax, 10) < parseInt(this.formFilters.numeroCteIni, 10)) {
            if (val === this.formFilters.numeroCteMax) {
                this.formFilters.numeroCteIni = this.formFilters.numeroCteMax;
            } else if (val === this.formFilters.numeroCteIni) {
                this.formFilters.numeroCteMax = this.formFilters.numeroCteIni;
            }
        }
    }

    /*
    * aplica os filtros de busca por item da página
    */
    private applyFilters() {
        this.serverParams.columnFilters = Object.assign(
            {},
            {
                id: this.formFilters.id || null,
                dataGeracao: this.formFilters.dataGeracao ?
                    new Range(
                        this.formFilters.dataGeracao[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataGeracao[1] + DateUtils.getMinAndMaxTime().max) : null,
                dataIntegracao: this.formFilters.dataIntegracao ?
                    new Range(
                        this.formFilters.dataIntegracao[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataIntegracao[1] + DateUtils.getMinAndMaxTime().max) : null,
                dataEnvio: this.formFilters.dataEnvio ?
                    new Range(
                        this.formFilters.dataEnvio[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataEnvio[1] + DateUtils.getMinAndMaxTime().max) : null,
                tipo: this.formFilters.tipo || null,
                layout: this.formFilters.layout || null,
                nrFatura: this.formFilters.nrFatura || null,
                nrOcorrencia: this.formFilters.nrOcorrencia || null,
                numeroCte: this.getNumeroCtePesq() ? this.getNumeroCtePesq() : null,
                serieCte: this.formFilters.serieCte || null,
            },
        );
    }

    private onRefreshClick(): void {
        this.formFilters = new EdiFormFilters();
        this.serverParams = {
            columnFilters: {},
            page: 1,
            perPage: 50,
        };
        this.$refs.table.$refs.paginationBottom.currentPage = 1;
        this.$refs.table.reset();
    }

    private hasPermission() {
        return this.hasAuthOnCurrentCompany(this.$route.meta.permission, this.currentCompany);
    }

    private beforeMount(): void {
        if (this.isAuthenticated) {
            this.updateAuthorities();
        }
        if (!this.hasPermission()) {
            this.$router.push('dashboard');
        }
    }

    private mounted() {
        this.$nextTick(() => {
            this.calcPositionTable();
        });
        window.addEventListener('resize', this.calcPositionTable);
    }

    private unmounted() {
        window.removeEventListener('resize', this.calcPositionTable);
    }
}
