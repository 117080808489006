











import { Component, Vue } from 'vue-property-decorator';
import {EdiTipoIntegracao, EdiTipoIntegracaoLookup} from '@/enums/EdiTipoIntegracao';

@Component({
    name: 'SelectTipoIntegracao',
})
export default class SelectTipoIntegracao extends Vue {

    public value: string = '';

    public get options(): EdiTipoIntegracao[] {
        return EdiTipoIntegracaoLookup.lookup;
    }
}
