export class EdiTipoIntegracao {

    public static ARQUIVO = new EdiTipoIntegracao('ARQUIVO', 1, 'Arquivo');
    public static COMUNICACAO = new EdiTipoIntegracao('COMUNICACAO', 2, 'Comunicacao');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
    ) {}

    public toString() {
        return this._key;
    }

    get key(): string {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }
}

export class EdiTipoIntegracaoLookup {

    public static lookup = [
        EdiTipoIntegracao.ARQUIVO,
        EdiTipoIntegracao.COMUNICACAO,
    ].sort((a, b) => a.value - b.value); // mant�m o array ordenado pelo valor

    public static default = EdiTipoIntegracao.ARQUIVO;

    public static getStatusByKey(value: string) {
        return this.lookup.find((v) => v.key === value) || this.default;
    }

    public static getStatusByValue(value: number) {
        return this.lookup.find((v) => v.value === value) || this.default;
    }

    private constructor() {}
}
