export class EdiTipoLayout {

    public static DOCCOB = new EdiTipoLayout('DOCCOB', 1, 'DOCCOB');
    public static CONEMB = new EdiTipoLayout('CONEMB', 2, 'CONEMB');
    public static OCORREN = new EdiTipoLayout('OCORREN', 3, 'OCORREN');
    public static Outros = new EdiTipoLayout('Outros', 99, 'Outros');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
    ) {}

    public toString() {
        return this._key;
    }

    get key(): string {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }
}

export class EdiTipoLayoutLookup {

    public static lookup = [
        EdiTipoLayout.DOCCOB,
        EdiTipoLayout.CONEMB,
        EdiTipoLayout.OCORREN,
        EdiTipoLayout.Outros,
    ].sort((a, b) => a.value - b.value); // mant�m o array ordenado pelo valor

    public static default = EdiTipoLayout.Outros;

    public static getStatusByKey(value: string) {
        return this.lookup.find((v) => v.key === value) || this.default;
    }

    public static getStatusByValue(value: number) {
        return this.lookup.find((v) => v.value === value) || this.default;
    }

    private constructor() {}
}
