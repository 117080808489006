

































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import DownloadButton from '@/components/commons/DownloadButton.vue';
import {FileTransferType} from '@/enums/FileTransferType';
import {StatusFaturaLookup} from '@/enums/StatusTarefa';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'EdiRowAction',
    components: {DownloadButton},
})
export default class EdiRowAction extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public rowData: any;

    public ctesGeradorasList: any = null;

    @Prop({default: () => FileTransferType.TXT})
    public readonly fileType!: FileTransferType;

    public dialogVisible: boolean = false;

    public openModal(ediId: number): void {
        this.ctesGeradorasList = null;
        this.$axios
        .get(
            `/edi/geradores/${this.currentCompany.id}`,
            {params: {ediId}},
        ).then((response) => {
            this.ctesGeradorasList = response.data;
            this.dialogVisible = true;
        }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        });
    }

    public downloadEdi(idEdi: number, urlArq: string) {
        this.$axios
            .get(
            `/edi/download/exists/${this.currentCompany.id}`,
            {params: {idEdi}},
        ).then((response) => {
            if (response.status !== 200) {
                Vue.$toast.error('Falha na requisição.');
            }
            if (response.data) {
                try {
                    this.$axios
                        .get(
                            `/edi/download/${this.currentCompany.id}`,
                            {params: {idEdi}},
                        ).then((dlResponse) => {
                                const blob = new Blob([dlResponse.data], {type: this.fileType.accceptHeader});
                                const url = window.URL.createObjectURL(blob);
                                const body = document.body;
                                const link = document.createElement('a');
                                link.href = url;
                                link.download = `${this.renderFileName(urlArq)}`;
                                body.appendChild(link);
                                link.click();
                                body.removeChild(link);
                                window.URL.revokeObjectURL(url);
                            },
                        ).catch((error) => {
                            console.log(error);
                            Vue.$toast.info('Não foi possível realizar o download.');
                        });
                } catch (error) {
                    console.log(error);
                    Vue.$toast.info('Não foi possível realizar o download.');
                }
            } else {
                Vue.$toast.info('Arquivo não encontrado no servidor.');
            }
        }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        });
    }

    public renderFileNameList(url: string): string {
        let fileName = this.renderFileName(url);
        if (fileName.length > 18) {
            fileName = fileName.substring(0, 18) + '...';
        }
        return fileName;
    }

    public renderFileName(url: string): string {
        let fileName = '';

        if (url) {
            for (let i = url.length; i > 0; i--) {
                if (url.charAt(i) === '\\' || url.charAt(i) === '/') {
                    break;
                }
                fileName += url.charAt(i);
            }
            fileName = fileName.split('').reverse().join('');
        }
        return fileName;
    }
}
