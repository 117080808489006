











import { Component, Vue } from 'vue-property-decorator';
import {EdiTipoLayout, EdiTipoLayoutLookup} from '@/enums/EdiTipoLayout';

@Component({
    name: 'SelectTipoLayout',
})
export default class SelectTipoLayout extends Vue {

    public value: string = '';

    public get options(): EdiTipoLayout[] {
        return EdiTipoLayoutLookup.lookup;
    }
}
